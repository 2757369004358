import React from 'react';
import '../styles/main.scss';
import logo from '../images/zatoka-pucka-logo.png';

class ComingSoon extends React.Component {
  state = {
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  };

  componentDidMount() {
    // update every second
    const tom = new Date(2019, 7, 2, 23, 59, 59);
    console.log(tom);
    tom.setDate(new Date(2019, 8, 2, 23, 59, 59).getDate());
    tom.setHours(0);
    tom.setMinutes(0);
    tom.setSeconds(0);
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(tom);
      date ? this.setState(date) : this.stop();
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;
    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  render() {
    const countDown = this.state;

    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: '#240094',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div>
          <img
            src={logo}
            style={{
              backgroundColor: '#fff',
              borderRadius: '50%',
              border: '50px solid #fff',
            }}
          />
        </div>
        <h1
          className="text-center"
          style={{ color: '#fff', display: 'block', margin: '25px 0 25px 0' }}
        >
          Zatoka Pucka - strona dostępna wkrótce...
        </h1>
        <div
          style={{
            color: '#fff',
            display: 'block',
            fontSize: '2rem',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              color: '#240094',
              backgroundColor: '#fff',
              border: '3px solid #f1f1f1',
              width: '75px',
              height: '75px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0px 1px',
            }}
          >
            {countDown.days}
            <span style={{ fontSize: '14px', marginLeft: '2px' }}>Dni</span>
          </div>
          <div
            style={{
              color: '#240094',
              backgroundColor: '#fff',
              border: '3px solid #f1f1f1',
              width: '75px',
              height: '75px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0px 1px',
            }}
          >
            {countDown.hours}
            <span style={{ fontSize: '14px', marginLeft: '2px' }}>Godz.</span>
          </div>
          <div
            style={{
              color: '#240094',
              backgroundColor: '#fff',
              border: '3px solid #f1f1f1',
              width: '75px',
              height: '75px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0px 1px',
            }}
          >
            {countDown.min}
            <span style={{ fontSize: '14px', marginLeft: '2px' }}>Min.</span>
          </div>
          <div
            style={{
              color: '#240094',
              backgroundColor: '#fff',
              border: '3px solid #f1f1f1',
              width: '75px',
              height: '75px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0px 1px',
            }}
          >
            {countDown.sec}
            <span style={{ fontSize: '14px', marginLeft: '2px' }}>Sek.</span>
          </div>
        </div>
      </div>
    );
  }
}

export default ComingSoon;
