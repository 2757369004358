import React from 'react';

import Layout from '../components/layout';
import ComingSoon from '../components/comingSoon';
const soon = process.env.GATSBY_SOON;

class IndexPage extends React.Component {
  render() {
    if (soon) {
      if (typeof window === `undefined`) {
        return null;
      } else if (window.location.search.indexOf('strona') === -1) {
        return <ComingSoon />;
      }
    }
    return <Layout mainPage />;
  }
}

export default IndexPage;
